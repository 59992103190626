<template>
  <div>
    <v-row v-if="user.isBulkGridEnabled">
      <v-col cols="12">
        <v-card>
          <div class="pa-4 d-flex flex-column w-full h-full">
            <div class="d-flex align-center">
              <span class="text-h6 font-weight-bold">
                View all duplicates
              </span>

              <v-spacer />

              <v-btn
                color="primary"
                outlined
                :to="{ name: 'root/on-my-plate/bulk-grid' }"
              >
                <span class="text-uppercase">Resolve</span>
                <v-icon right>$mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6" lg="4">
        <ChartDoughnutCard
          id="reviewDuplicateSetsTile"
          :is-loading="isLoadingDupesToReviewStats"
          title="Duplicate sets to be reviewed"
          title-tooltip="Duplicate sets to be reviewed by entity"
          :items="dupeSetsToReviewStatsByEntity"
          :description="`${dupeSetsToReviewCount} Duplicate sets`"
          :to="{ name: 'root/on-my-plate/dupe-sets-to-review' }"
        />
      </v-col>
      <v-col cols="12" md="6" lg="4">
        <ChartDoughnutCard
          id="reviewAutoMergesTile"
          :is-loading="isLoadingAutoMergesToReviewStats"
          title="Auto-merges to be reviewed"
          title-tooltip="Auto-merges to be reviewed by entity"
          :items="autoMergesToReviewStatsByEntity"
          :description="`${autoMergesToReviewCount} Duplicate sets`"
          :to="{ name: 'root/on-my-plate/auto-merges-to-review' }"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ChartDoughnutCard from '@/components/common/ChartDoughnutCard'
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  components: {
    ChartDoughnutCard,
  },
  data() {
    return {
      isLoadingDupesToReviewStats: false,
      isLoadingAutoMergesToReviewStats: false,
    }
  },
  computed: {
    ...mapGetters('dashboard', [
      'dupeSetsToReviewCount',
      'dupeSetsToReviewStatsByEntity',
      'autoMergesToReviewCount',
      'autoMergesToReviewStatsByEntity',
    ]),
    ...mapState('auth', ['user']),
  },
  mounted() {
    this.fetchDupesToReviewStats()
    this.fetchAutoMergesToReviewStats()
  },
  methods: {
    ...mapActions('dashboard', [
      'getDupeSetsToReviewStats',
      'getAutoMergesToReviewStats',
    ]),
    async fetchDupesToReviewStats({ start, end } = {}) {
      this.isLoadingDupesToReviewStats = true
      await this.getDupeSetsToReviewStats({ start, end })
      this.isLoadingDupesToReviewStats = false
    },
    async fetchAutoMergesToReviewStats({ start, end } = {}) {
      this.isLoadingAutoMergesToReviewStats = true
      await this.getAutoMergesToReviewStats({ start, end })
      this.isLoadingAutoMergesToReviewStats = false
    },
  },
}
</script>
